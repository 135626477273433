import { useEffect, useState } from "react";

import { Hero } from "../../Components/Hero";
import { AnnouncementBar } from "../../Components/AnnouncmentBar";
import { Showcase } from "../../Components/Showcase";
import { Entry } from "../../Components/Entry";

import LOGO from "../../Assets/logo.svg";
import VIDEO_BG from "../../Assets/video-bg.mov";

import "./index.css";

const Logo = () => {
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowLogo(true);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <img className={`logo ${showLogo ? "show" : ""}`} src={LOGO} alt="logo" />
  );
};

export const Home = () => {
  const [removeEntry, setRemoveEntry] = useState(false);
  const [firstVisitComplete, setFirstVisitComplete] = useState(
    sessionStorage.getItem("firstVisitComplete")
  );

  useEffect(() => {
    if (!firstVisitComplete) {
      const interval = setTimeout(() => {
        document.getElementById("entry").classList.add("hide");
        sessionStorage.setItem("firstVisitComplete", "true");
        setFirstVisitComplete(true);
      }, 8000);

      return () => clearTimeout(interval);
    } else if (sessionStorage.getItem("firstVisitComplete")) {
      setFirstVisitComplete(true);
    }
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setRemoveEntry(true);
    }, 9500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="home">
        <div
          id="entry"
          className={`entry ${removeEntry || firstVisitComplete ? "hide" : ""}`}
          style={{
            pointerEvents: `${
              !removeEntry && !firstVisitComplete ? "all" : "none"
            }`,
          }}
        >
          <Logo />
          <video
            className="entry-video"
            width="320"
            height="240"
            muted
            autoPlay
            playsInline
          >
            <source src={VIDEO_BG} type="video/mp4" />
          </video>
        </div>
        <Hero />
        <AnnouncementBar />
        <Showcase />
      </div>
    </>
  );
};
