import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header } from "./Components/Header";
import { AsideMenu } from "./Components/AsideMenu";
import { Footer } from "./Components/Footer";

import { Home } from "./Routes/Home";
import { Contact } from "./Routes/Contact";

export const App = () => {
  return (
    <>
      <main className="main">
        <BrowserRouter>
          <Header />
          <AsideMenu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </main>
    </>
  );
};
